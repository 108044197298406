import React from 'react';
import logo from './assets/logo.png';
import maochi from './assets/maochi.png';
import x from './assets/IconX.webp';
import tg from './assets/iconTg.webp';
import dex from './assets/IconDex.webp';
import meme_one from './assets/meme_one.png';
import meme_two from './assets/meme_two.png';
import meme_three from './assets/meme_three.png';
import meme_four from './assets/meme_four.png';
import './App.css';

function App() {
  return (
    <div className="App">
        <div className="main-container">
            <div className="nav-strip">
                <img className="nav-logo" src={logo} alt=""/>
                <h2 className="nav-text">maochi</h2>
            </div>
            <div className="title-container">
                <h1 className="title">$maochi</h1>
            </div>
            <img className="maochi-container" src={maochi} alt=""/>
            <div className="description-container">
                <h2 className="description">māo (猫) + michi = $maochi</h2>
            </div>
            <h2 className="contract">CA: THWL4cz8KgjyRpcRZggqpdEhEgNjJ8kHqs</h2>
            <div className="info-container">
                <button className="buy-button">BUY HERE</button>
                <div className="socials-container">
                    <a className="social-button" href="https://twitter.com/maochiontron" target="_">
                        <img className="social-image" src={x} alt=""/>
                    </a>
                    <a className="social-button" href="https://t.me/maochiontron" target="_">
                        <img className="social-image" src={tg} alt=""/>
                    </a>
                    <a className="social-button" href="https://sunpump.meme/token/THWL4cz8KgjyRpcRZggqpdEhEgNjJ8kHqs" target="_">
                        <img className="social-image" src={dex} alt=""/>
                    </a>
                </div>
            </div>
            <h1 className="meme-text">maochi memes</h1>
            <div className="meme-container">
                <img className="meme-image" src={meme_one} alt=""/>
                <img className="meme-image" src={meme_two} alt=""/>
                <img className="meme-image" src={meme_three} alt=""/>
            </div>
            <div className="memes-container">
                <img className="meme-image" src={meme_four} alt=""/>
                <img className="meme-image" src={meme_three} alt=""/>
                <img className="meme-image" src={meme_two} alt=""/>
            </div>
        </div>
    </div>
  );
}

export default App;
